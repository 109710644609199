const DayViewAppBarMobile = () => import("./DayViewAppBarMobile");
const DayViewAppBarDesktop = () => import("./DayViewAppBarDesktop");

export default {
  functional: true,
  inheritAttrs: false,
  render: (h, context) => {
    const responsiveComponent = context.parent.$vuetify.breakpoint.smAndDown
      ? DayViewAppBarMobile
      : DayViewAppBarDesktop;

    return h(
      responsiveComponent,
      {
        props: context.props,
        ...context.data,
        scopedSlots: context.scopedSlots,
      },
      context.$slots.default
    );
  },
};
